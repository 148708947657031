import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = () => {
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [privacy, setPrivacy] = useState(false);
	const [error, setError] = useState("");
	const [feedback, setFeedback] = useState("");

	// EMAILJS
	const SERVICE_ID = "unfioredimaestra";
	const TEMPLATE_ID = "unfioredimaestra_form";
	const USER_ID = "user_Cl0vLHF0bWUYLX9bkz5FT";

	const handleSubmit = e => {
		e.preventDefault();
		setFeedback("");
		if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
			setError("Inserisci un indirizzo email valido");
			return;
		}
		if (!message) {
			setError("Inserisci un messaggio");
			return;
		}
		if (!privacy) {
			setError(
				"È necessario dare il consenso per il trattamento dei dati personali"
			);
			return;
		}
		if (error === "") {
			setFeedback("Invio...");
			emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
				result => {
					setFeedback("Messaggio inviato!");
					setEmail("");
					setMessage("");
					setPrivacy(false);
				},
				error => {
					setFeedback("");
					setError("Ops, c'è stato un errore");
				}
			);
		}
	};

	return (
		<form className="contact-form" onSubmit={handleSubmit}>
			<input
				type="text"
				name="email"
				value={email}
				placeholder="la tua email"
				onChange={e => {
					setError("");
					setEmail(e.target.value);
				}}
			/>
			<textarea
				name="message"
				value={message}
				placeholder="messaggio"
				onChange={e => {
					setError("");
					setMessage(e.target.value);
				}}
			/>
			<span>
				<input
					type="checkbox"
					name="privacy"
					defaultChecked={privacy}
					onChange={e => {
						setError("");
						setPrivacy(!privacy);
					}}
				/>
				<label htmlFor="privacy">
					{" "}
					Accetto le condizioni sul trattamento dei dati personali
				</label>
			</span>
			<a
				href="https://www.iubenda.com/privacy-policy/81480454"
				className="privacy-btn iubenda-white iubenda-embed"
				title="Privacy Policy"
			>
				Privacy Policy
			</a>
			{(function (w, d) {
				var loader = function () {
					var s = d.createElement("script"),
						tag = d.getElementsByTagName("script")[0];
					s.src = "https://cdn.iubenda.com/iubenda.js";
					tag.parentNode.insertBefore(s, tag);
				};
				if (w.addEventListener) {
					w.addEventListener("load", loader, false);
				} else if (w.attachEvent) {
					w.attachEvent("onload", loader);
				} else {
					w.onload = loader;
				}
			})(window, document)}

			<input type="submit" value="Invia messaggio!" />
			<p className="error">{error}</p>
			<p className="feedback">{feedback}</p>
		</form>
	);
};

export default ContactForm;
