import React from "react";
import { useParams } from "react-router-dom";

import Footer from "./Footer";
import NotFound from "./NotFound";
import Articles from "../../data/Articles";

const Article = () => {
	const { articleId } = useParams();
	const article = Articles.find(article => article.id === articleId);
	if (article) {
		return (
			<section id="article">
				<div className="article-header">
					<h1 className="article-title">{article.title}</h1>
					<p className="article-date">{article.date}</p>
					<img
						src={
							process.env.PUBLIC_URL +
							"/img/unfioredimaestra-" +
							article.id +
							".jpg"
						}
						className="article-image"
						alt={`Immagine ${article.title}`}
					/>
				</div>
				<div className="article-content">{article.text}</div>
				<Footer />
			</section>
		);
	} else {
		return <NotFound />;
	}
};

export default Article;
