import React from "react";
import { NavLink } from "react-router-dom";

import Footer from "./Footer";
import Articles from "../../data/Articles";

const Blog = () => {
	return (
		<section id="blog">
			<div className="page-header">
				<h1 className="page-title">Blog</h1>
			</div>
			<div className="page-content">
				<ul className="blog-list">
					{Articles.map(article => (
						<li key={article.id} className="article">
							<NavLink className="link" exact to={`/blog/${article.id}`}>
								<h3 className="article-title">{article.title}</h3>
								<img
									src={
										process.env.PUBLIC_URL +
										"/img/unfioredimaestra-" +
										article.id +
										".jpg"
									}
									className="article-image"
									alt="Immagine Articolo"
								/>
								<p className="article-preview">{article.preview}</p>
							</NavLink>
						</li>
					))}
				</ul>
			</div>
			<Footer />
		</section>
	);
};

export default Blog;
