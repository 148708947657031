import React from "react";

const Footer = () => {
	return (
		<footer>
			<ul>
				{/* <li>
					<a
						href="https://www.instagram.com/unfioredimaestra"
						rel="noopener noreferrer"
						target="_blank"
					>
						<i className="fab fa-instagram"></i>
					</a>
				</li> */}
				<li>
					<a href="mailto:info@fiorenzasuagher.it">info@fiorenzasuagher.it</a>
				</li>
				<li>Copyright © Fiorenza Suagher 2023</li>
				<li>
					<a
						href="https://www.iubenda.com/privacy-policy/81480454"
						className="privacy-btn iubenda-black iubenda-embed"
						title="Privacy Policy"
					>
						Privacy Policy
					</a>
				</li>
			</ul>
		</footer>
	);
};

export default Footer;
