import React from "react";
import Footer from "./Footer";

const ViaggioMusicale = () => {
	return (
		<section id="viaggio-musicale">
			<div className="page-header">
				<h1 className="page-title">Il Viaggio Musicale</h1>
			</div>
			<div className="page-content">
				<p className="paragraph">Benvenuto nel VIAGGIO MUSICALE!</p>
				<p className="paragraph">
					Io sono Fiore e sono insegnante di canto moderno e di flauto traverso.
				</p>
				<p className="paragraph">
					Come maestra di musica la mia mission è sostenerti nel viaggio
					musicale che ti porta a{" "}
					<strong>esplorare le tue possibilità espressive.</strong> Perché credo
					che conoscere e coltivare le proprie capacità e potenzialità ci renda
					persone più <strong>sicure, soddisfatte e felici.</strong>
				</p>
				<p className="paragraph">
					Credo nella lezione di musica come un{" "}
					<strong>momento tutto per te.</strong> Si chiude la porta: il mondo e
					i problemi ne restano fuori. C'è spazio per te, per la scoperta del{" "}
					<strong>tuo io musicale</strong>, delle{" "}
					<strong>tue possibilità</strong> e della{" "}
					<strong>tua espressività.</strong> È il momento di esplorare e
					coltivare capacità che magari pensavi di non avere!
				</p>
				<p className="paragraph">
					Il viaggio musicale lo percorriamo insieme. È un percorso che ti
					porterà ad avere una maggior fiducia in te stesso e nelle tue
					possibilità!
				</p>
				<p className="paragraph">
					Come in ogni viaggio, avremo con noi un equipaggiamento, degli
					occorrenti indispensabili. Sono i valori in cui credo, che mi ispirano
					e mi guidano ogni giorno nel mio lavoro.
				</p>
				<div className="paragraph">
					<ul className="equipaggiamento">
						<li>
							<strong>Empatia: </strong>ascoltarsi, comprendersi e sostenersi
							positivamente.
						</li>
						<li>
							<strong>Entusiasmo: </strong>l'energia reciproca che dà la carica
							alla nostra passione e motivazione.
						</li>
						<li>
							<strong>Curiosita': </strong>la voglia di scoprirsi e di mettersi
							in gioco. Restare aperti alle possibilità.
						</li>
						<li>
							<strong>Chiarezza: </strong>visione semplice e trasparente del
							percorso nei suoi aspetti.
						</li>
						<li>
							<strong>Consapevolezza: </strong>capire cosa si sta facendo e
							perché.
						</li>
						<li>
							<strong>Tranquillita' e Serenita': </strong>non avere ansie da
							prestazione, risultato o giudizio. Ascoltarsi, darsi tempo e
							sentirsi bene in quello che si fa.
						</li>
						<li>
							<strong>Studio e Aggiornamento: </strong>non ristagnare nella zona
							di comfort, ma conoscere per procedere e migliorarsi sempre.
						</li>
						<li>
							<strong>Rispetto e Liberta': </strong>scambiare reciprocamente le
							proprie idee, opinioni, richieste.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</section>
	);
};

export default ViaggioMusicale;
