import React from "react";
import Div100vh from "react-div-100vh";

const Home = () => {
	return (
		<Div100vh>
			<section id="home">
				<div className="mission">
					<img
						className="logo"
						src={process.env.PUBLIC_URL + "/img/unfioredimaestra-logo-home.png"}
						alt="Logo Un Fiore di Maestra"
					/>
					<p>
						Ciao, benvenuto! Sono Fiore e sono una maestra di musica.
						<br />
						Ti sostengo nel viaggio musicale che ti porta a esplorare le tue
						possibilita' espressive.
					</p>
				</div>
			</section>
		</Div100vh>
	);
};

export default Home;
