import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// STYLE
import "../scss/App.scss";
import "../scss/_notch-fix.css";
// UTILITIES
import ScrollToTop from "./utilities/ScrollToTop";
// COMPONENTS
import Header from "./components/Header";
import Home from "./components/Home";
import ChiSono from "./components/ChiSono";
import ViaggioMusicale from "./components/ViaggioMusicale";
import Blog from "./components/Blog";
import Article from "./components/Article";
import Contatti from "./components/Contatti";
import NotFound from "./components/NotFound";

const App = () => {
	const pageHeight = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	};
	pageHeight();
	window.addEventListener("resize", () => {
		pageHeight();
	});
	window.addEventListener("orientationchange", () => {
		pageHeight();
	});

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Header />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/chi-sono" component={ChiSono} />
				<Route exact path="/il-viaggio-musicale" component={ViaggioMusicale} />
				<Route exact path="/blog" component={Blog} />
				<Route exact path="/blog/:articleId" component={Article} />
				<Route exact path="/contatti" component={Contatti} />
				<Route path="*">
					<Redirect to={"/"} />
				</Route>
				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
};

export default App;
