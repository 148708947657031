import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Div100vh from "react-div-100vh";

const Header = () => {
	const [dropdown, setDropdown] = useState(false);
	const setMenuClasses = () => {
		return dropdown ? "menu-mobile open" : "menu-mobile close";
	};
	const setMenuIcon = () => {
		return dropdown ? "fas fa-times" : "fas fa-bars";
	};
	const history = useHistory();
	history.listen(() => setDropdown(false));

	return (
		<header>
			<ul className="menu-pc">
				<li className="menu-item">
					<NavLink activeClassName="selected" exact to="/chi-sono">
						Chi Sono
					</NavLink>
				</li>
				<li className="menu-item">
					<NavLink activeClassName="selected" exact to="/il-viaggio-musicale">
						Il Viaggio Musicale
					</NavLink>
				</li>
				<li className="menu-item">
					<NavLink activeClassName="selected" exact to="/">
						Home
					</NavLink>
				</li>
				<li className="menu-item">
					<NavLink activeClassName="selected" exact to="/blog">
						Blog
					</NavLink>
				</li>
				<li className="menu-item">
					<NavLink activeClassName="selected" exact to="/contatti">
						Contatti
					</NavLink>
				</li>
			</ul>
			<Div100vh className={setMenuClasses()}>
				<div className="hamburger" onClick={() => setDropdown(!dropdown)}>
					<i className={setMenuIcon()}></i>
				</div>
				<ul className="dropdown">
					<li className="menu-item">
						<NavLink exact to="/">
							Home
						</NavLink>
					</li>
					<li className="menu-item">
						<NavLink exact to="/chi-sono">
							Chi Sono
						</NavLink>
					</li>
					<li className="menu-item">
						<NavLink exact to="/il-viaggio-musicale">
							Il Viaggio Musicale
						</NavLink>
					</li>
					<li className="menu-item">
						<NavLink exact to="/blog">
							Blog
						</NavLink>
					</li>
					<li className="menu-item">
						<NavLink exact to="/contatti">
							Contatti
						</NavLink>
					</li>
					{/* <li className="menu-item">
						<a
							href="https://www.instagram.com/unfioredimaestra"
							rel="noopener noreferrer"
							target="_blank"
						>
							<i className="fab fa-instagram"></i>
						</a>
					</li> */}
				</ul>
			</Div100vh>
		</header>
	);
};

export default Header;
