import React from "react";
import Footer from "./Footer";
import Div100vh from "react-div-100vh";

const NotFound = () => {
	return (
		<Div100vh>
			<section id="not-found">
				<div className="page-content">
					<h2>404 Page Not Found</h2>
				</div>
				<Footer />
			</section>
		</Div100vh>
	);
};

export default NotFound;
