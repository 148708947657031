import React from "react";
import Footer from "./Footer";
import ContactForm from "./ContactForm";

const Contatti = () => {
	return (
		<section id="contatti">
			<div className="page-header">
				<h1 className="page-title">Contatti</h1>
			</div>
			<div className="page-content">
				<div className="left">
					<div className="contacts">
						<p>FIORENZA SUAGHER</p>
						<p>
							<a href="mailto:info@fiorenzasuagher.it">info@fiorenzasuagher.it</a>
						</p>
						{/* <a
							href="https://www.instagram.com/unfioredimaestra/"
							rel="noopener noreferrer"
							target="_blank"
						>
							<i className="fab fa-instagram"></i>
							<span className="user">unfioredimaestra</span>
						</a> */}
					</div>
					<div className="schools">
						<p>Mi trovi nella provincia di Bergamo in queste scuole:</p>
						<ul>
							<li className="school">
								<a
									className="school-name"
									href="https://accademia.bergamo.it"
									rel="noopener noreferrer"
									target="_blank"
								>
									L'Accademia Centro Studi Musicali
								</a>
								<p className="school-address">
									<b>Bergamo</b>, via V. Ghislandi, 6
								</p>
							</li>
							<li className="school">
								<a
									className="school-name"
									href="https://cdpm.it"
									rel="noopener noreferrer"
									target="_blank"
								>
									Centro Didattico produzione Musica
								</a>
								<p className="school-address">
									<b>Bergamo</b>, via E. De Amicis, 6
								</p>
							</li>
							<li className="school">
								<a
									className="school-name"
									href="http://bandadinembro.altervista.org/scuola.php"
									rel="noopener noreferrer"
									target="_blank"
								>
									Banda di Nembro - Scuola di Musica
								</a>
								<p className="school-address">
									<b>Nembro</b> (Bg), Casa della Musica, via Stazione
								</p>
							</li>
							<li className="school">
								<a
									className="school-name"
									href="https://milleeunanota.com"
									rel="noopener noreferrer"
									target="_blank"
								>
									Milleeunanota - Scuola di Musica
								</a>
								<p className="school-address">
									<b>Scanzorosciate</b> (Bg), via F. Martinengo Colleoni, 38
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="right">
					<div className="contact-form">
						<p className="scrivimi">
							Vuoi avere più informazioni?
							<br />
							Scrivimi qui e sarò felice di risponderti!
						</p>
						<ContactForm />
					</div>
					<div className="logo">
						<img
							src={process.env.PUBLIC_URL + "/img/unfioredimaestra-logo.png"}
							alt="Logo Un Fiore di Maestra"
						/>
					</div>
				</div>
			</div>
			<Footer />
		</section>
	);
};

export default Contatti;
