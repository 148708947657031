import React from "react";

const Articles = [
	{
		id: "voce-ha-bisogno-di-acqua",
		title: "5 segnali che la tua voce ha bisogno di acqua",
		date: (
			<React.Fragment>
				Ottobre 2020 | <i class="far fa-clock"></i> 2 min
			</React.Fragment>
		),
		preview: `Se ti stai dicendo "oddio il solito post che mi dice quanti litri di
		acqua devo bere al giorno" sappi che...`,
		text: (
			<React.Fragment>
				<p className="paragraph">
					Se ti stai dicendo "oddio il solito post che mi dice quanti litri di
					acqua devo bere al giorno" sappi che stai sbagliando! Questo non è il
					solito post in cui ti si dice quanti litri di acqua devi bere al
					giorno.
				</p>
				<p className="paragraph">
					In questo post parlo di quando ti senti la voce stanca, poco
					performante e di quando la senti "abbassata". Questi che ti ho scritto
					sono{" "}
					<strong>
						alcuni segnali che la tua voce usa per dirti "ehi! Ho bisogno di
						acqua!".
					</strong>
				</p>
				<h3 className="titoletto">Perché alla voce serve acqua</h3>
				<p className="paragraph">
					La fonte della nostra voce sono le corde vocali. Sono due lembi belli
					bianchi (quando sono sani) che quando vuoi parlare o cantare si
					chiudono al passaggio del fiato e iniziano magicamente a vibrare!
					<br />
					<strong>
						Le corde vocali sono rivestite da mucose che garantiscono la loro
						salute e facilitano il loro movimento ondulatorio.
					</strong>
				</p>
				<p className="image">
					<img
						src={
							process.env.PUBLIC_URL + "/img/unfioredimaestra-corde-vocali.png"
						}
						alt="Immagine Corde Vocali"
					/>
				</p>
				<p className="paragraph">
					Quando le corde vocali sono belle idratate questo movimento è fluido e
					scorre liberamente. Ma{" "}
					<strong>
						quando sono disidratate questo movimento diventa più difficile
					</strong>
					, perché vanno in attrito. E così la nostra voce inizia a stancarsi!
				</p>
				<h3 className="titoletto">
					Quando la nostra voce ha bisogno di più acqua?
				</h3>
				<p className="paragraph">
					<ul className="num-list">
						<li>
							Quando senti il continuo bisogno di schiarirti la voce
							(tossettina... a proposito... non farla, fa peggio! Manda giù un
							po' d'acqua!);
						</li>
						<li>Quando senti il tono di voce abbassato;</li>
						<li>Quando hai la sensazione di fatica nel parlare;</li>
						<li>
							Quando hai la sensazione di non avere la voce "a pieno" (aria nel
							suono);
						</li>
						<li>
							Quando stai per tante ore in un ambiente secco (e soprattutto lì
							ci devi parlare o cantare!).
						</li>
					</ul>
				</p>
				<h3 className="titoletto">Quando bere?</h3>
				<p className="paragraph">
					Purtroppo il "bicchiere al bisogno" non serve a tanto... perché ci
					vuole un po' prima che l'acqua "entri in circolo" e vada ad idratare!
					E anche bersi un litro prima della performance non va bene! (Rischiamo
					di attivare gli acidi dello stomaco e andare a creare un altro
					problema sulle corde vocali!){" "}
					<strong>Bisogna essere costanti!</strong>
					<br />
					Se come me fai fatica a bere tanto durante la giornata ti svelo{" "}
					<strong>il mio trucchetto.</strong> Quando so che dovrò parlare tanto
					o cantare inizio a bere tanta acqua nei giorni precedenti! Devo dire
					però che da quando uso le bottiglie termiche sia in casa sia fuori ho
					migliorato molto il mio "ricordarmi di bere acqua".
				</p>
				<h3 className="titoletto">Altri metodi di idratazione</h3>
				<p className="paragraph">
					L'idratazione che riceviamo dall'acqua e dagli oligoelementi che
					troviamo negli alimenti è insostituibile! Ci sono tuttavia anche dei{" "}
					<strong>
						modi "esterni" con cui puoi andare a idratare la tua voce
					</strong>{" "}
					in caso di bisogno e per prevenire la secchezza vocale.
				</p>
				<p className="paragraph">
					Ad esempio puoi:
					<ul>
						<li>
							Bagnare una garzina e tenerla sotto il naso respirando così per
							una quindicina di minuti;
						</li>
						<li>
							In questo particolare periodo potresti usare la mascherina, non
							contaminata, invece della garzina, bagnarla e respirare con la
							mascherina bagnata in un ambiente non a rischio di contaminazione;
						</li>
						<li>Usare un umidificatore negli ambienti più secchi.</li>
					</ul>
				</p>
				<p className="paragraph">
					Mantenere una buona idratazione alla voce serve solo ai cantanti? Eh
					no! Serve a tutti... perché <strong>tutti usiamo la voce.</strong>{" "}
					Anzi, serve moltissimo a chi per lavoro (o per esuberanza) parla
					tanto! Infatti la nostra voce si stanca di più a parlare che non a
					cantare!
				</p>
				<p className="paragraph">
					Allora, inizierai a bere un po' più di acqua quando la tua voce inizia
					a stancarsi?
				</p>
				<p className="paragraph">
					L'argomento di questo post lo trovi anche sul mio profilo instagram{" "}
					<a
						href="https://www.instagram.com/unfioredimaestra"
						rel="noopener noreferrer"
						target="_blank"
					>
						@unfioredimaestra
					</a>{" "}
					dove puoi trovare altri rimedi per la salute della tua voce!
				</p>
			</React.Fragment>
		),
	},
	{
		id: "scegliere-scuola-di-musica",
		title: "Come scegliere la scuola di musica giusta per te",
		date: (
			<React.Fragment>
				Settembre 2020 | <i class="far fa-clock"></i> 2 min
			</React.Fragment>
		),
		preview: `Quando si pensa di inziare un percorso di musica, oltre a decidere che
		strumento provare, sorge la domanda...`,
		text: (
			<React.Fragment>
				<p className="paragraph">
					Quando si pensa di inziare un percorso di musica, oltre a decidere che
					strumento provare, sorge la domanda: "Dove vado a imparare?"
				</p>
				<p className="paragraph">
					A volte potrebbe succedere il contrario, ossia hai visto la pubblicità
					di una scuola che ti ha colpito e allora decidi di recarti lì e poi
					scegli il tuo corso.
				</p>
				<p className="paragraph">
					Non sempre la scelta è semplice.{" "}
					<strong>
						Quasi in tutte le città oggi si trova una scuola di musica
					</strong>
					, che sia di un'associazione culturale o di un ente privato.{" "}
					<strong>Come scegliere quella giusta per te?</strong>
				</p>
				<p className="paragraph">
					<strong>La scelta è sicuramente più semplice se:</strong>
					<br />
					<ul>
						<li>
							<strong>hai già idea dell'insegnante da cui vuoi andare.</strong>{" "}
							Perché lo conosci di persona o perché te ne hanno parlato bene
							delle persone che conosci. In questo caso sei a cavallo!
						</li>
						<li>
							<strong>ti hanno consigliato una scuola</strong> delle persone che
							già la frequentano e che ti hanno riportato un'esperienza
							positiva.
						</li>
						<li>
							hai avuto modo di frequentare gli openday e sei rimasto colpito da
							una scuola in particolare!
						</li>
					</ul>
				</p>
				<p className="paragraph">
					Ma immaginiamo che tu non abbia nessuna di queste opinioni e ti trovi
					a scegliere tra tante scuole con proposte diverse. La scelta oggi è
					davvero ampia. Per questo ho pensato di scrivere{" "}
					<strong>una guida</strong> per potersi orientare nella giungla degli
					openday delle scuole di musica! Vediamola.
				</p>
				<h3 className="titoletto">Prima di scegliere... chiarisci!</h3>
				<p className="paragraph">
					Il primo passo fondamentale è{" "}
					<strong>
						chiarire con te stesso <b>perché</b> vuoi iniziare un corso
					</strong>{" "}
					di strumento o un corso di canto. Cosa vuoi ricevere da questo corso
					di musica? Cerca di capire e definire quali sono i tuoi obiettivi.
					Questo ti può aiutare nella scelta.
				</p>
				<h3 className="titoletto">Obiettivo "personal"</h3>
				<p className="paragraph">
					Il tuo obiettivo è <strong>fare musica per te stesso</strong>, per
					provare a fare qualcosa di nuovo? La scuola per te potrebbe essere una
					scuola che non ti "obbliga" a sostenere degli esami.
				</p>
				<h3 className="titoletto">Obiettivo "performer"</h3>
				<p className="paragraph">
					Il tuo obiettivo è studiare per poi{" "}
					<strong>suonare in un gruppo e fare esibizioni?</strong> Cerca una
					scuola che ti dia la possibilità di fare delle serate o dei concerti
					oltre al saggio e che abbia laboratori di gruppo, per conoscere altre
					persone e per fare pratica dell'esperienza "band".
				</p>
				<h3 className="titoletto">Obiettivo "professionist"</h3>
				<p className="paragraph">
					Il tuo obiettivo è studiare musica per fare un percorso che ti possa
					portare ad uno <strong>sbocco lavorativo?</strong> Cerca una scuola
					che ti permetta di sostenere esami valutando bene la loro validità e
					che abbia corsi o laboratori complementari per approfondire il tuo
					studio.
				</p>
				<p className="image">
					<img
						src={
							process.env.PUBLIC_URL +
							"/img/unfioredimaestra-scegliere-scuola-di-musica-2.jpg"
						}
						alt="Immagine Scegliere Scuola di Musica"
					/>
				</p>
				<h3 className="titoletto">Obiettivo "specialist"</h3>
				<p className="paragraph">
					Stai già studiando ma vuoi qualcosa di più? Cerca una{" "}
					<strong>scuola più specializzata</strong> o che abbia dei corsi
					diversi rispetto a quelli che stai facendo. Chiedi informazioni sugli
					insegnanti e su cosa sono specializzati.
				</p>
				<h3 className="titoletto">Obiettivo "kids"</h3>
				<p className="paragraph">
					Stai cercando una scuola per il tuo baby musicista? Informati su quali
					scuole hanno{" "}
					<strong>insegnanti a cui piace lavorare con i bambini</strong> e che
					utilizzano una <strong>didattica aggiornata e preparata</strong> per
					lavorare con le varie fasce d'età.
				</p>
				<p className="paragraph">Questi sono alcuni esempi di obiettivi.</p>
				<h3 className="titoletto">Ricorda che:</h3>
				<ul>
					<li>
						<p className="paragraph">
							Ogni scuola ha le sue caratteristiche. Partire da una scuola più
							"semplice" non esclude che tu possa trovare un bravissimo
							insegnante e che il tuo percorso potrà diventare in futuro più
							specializzato.
						</p>
					</li>
					<li>
						<p className="paragraph">
							A una scuola non ti leghi per sempre! Una volta fatta la tua
							scelta e iniziata la scuola... se non ti trovi bene hai sempre la
							possibilità di cambiare. Al contrario invece{" "}
							<strong>
								trovare una scuola e un insegnante con cui ti trovi davvero bene
								potrebbe segnare la tua vita!
							</strong>
						</p>
					</li>
				</ul>
			</React.Fragment>
		),
	},
	{
		id: "tutti-possono-fare-musica",
		title: "Tutti possono fare musica?",
		date: (
			<React.Fragment>
				Settembre 2020 | <i class="far fa-clock"></i> 3 min
			</React.Fragment>
		),
		preview: `"Sono troppo grande, ormai è tardi." "Sono troppo piccolo per iniziare?"
		"Eh no ma non ho mai fatto niente...`,
		text: (
			<React.Fragment>
				<p className="paragraph">
					"Sono troppo grande, ormai è tardi." "Sono troppo piccolo per
					iniziare?"
					<br />
					"Eh no ma non ho mai fatto niente di musica..."
					<br />
					"Sono stonato." "Non sono portato."
				</p>
				<p className="paragraph">
					Quante volte ti è capitato di dire o di sentire queste frasi? Io le
					sento spesso!
					<br />
					A volte quando mi capita di dire che sono insegnante di canto mi sento
					dire: "Che bello! Ah ma io sono stonato... non potrei farlo!". Ed è
					proprio lì che mi piacerebbe cogliere la sfida!
					<br />
					Ora, se sei tra quelli che almeno una volta nella vita hanno pensato{" "}
					<b>"non sono portato per la musica"</b> questo post è per te!
				</p>
				<h3 className="titoletto">"No ma io non sono portato"</h3>
				<p className="paragraph">
					Quello che mi dispiace di più è vedere come a volte una persona si
					preclude la musica perché pensa che sia troppo grande, perché non ha
					mai fatto niente prima, perché pensa di non essere portato.
					<br />
					Ma perché siamo convinti che
					<strong>
						{" "}
						se non abbiamo un talento pazzesco non possiamo fare musica?{" "}
					</strong>
					Soprattutto, chi ti ha detto se hai o non hai questo benedetto
					talento? Te l'ha detto un maestro? Gli amici? Te lo sei detto da solo?
				</p>
				<p className="paragraph">
					Nella nostra società e cultura ci siamo formati l'idea che solo i
					piccoli Mozart diventano grandi musicisti. Cioè il bambino prodigio,
					con un grandissimo talento fin da piccolo. Abbiamo l'idea che{" "}
					<strong>solo chi ha il talento</strong> "è portato", può fare musica e
					può "sfondare". Se uno non ha il talento, basta... non può certo
					sperare di raggiungere chissà che risultati! Per non parlare poi delle
					gare in cui si "gioca" a chi ce l'ha di più e chi ce l'ha di meno!
				</p>
				<p className="paragraph">
					Ma se ti dicessi che il talento non esiste? Se ti dicessi che fare
					musica non significa solo voler diventare un grandissimo concertista?
					Cosa è allora il talento, da dove arriva?
				</p>
				<h3 className="titoletto">Il talento musicale</h3>
				<p className="paragraph">
					Quando pensiamo al talento, lo pensiamo come una grandissima abilità
					che ci è stata data, così, per dono divino. In pratica{" "}
					<strong>o ce l'hai o non ce l'hai.</strong> E se non ce l'hai non ci
					puoi fare nulla. E soprattutto lo consideriamo come una cosa che hanno
					solo pochi eletti.
					<br />
					<strong>E se adesso ti dicessi che il talento non esiste</strong>? O
					meglio: non esiste il talento come lo intendiamo noi. Cioè come una
					cosa innata, una "scienza infusa". Ora ti svelerò un segreto.{" "}
					<b>Tutti nasciamo con un talento musicale.</b>
				</p>
				<h3 className="titoletto">L'attitudine musicale di ognuno di noi</h3>
				<p className="paragraph">
					<strong>Ognuno di noi nasce con un'attitudine musicale.</strong> Con
					attitudine musicale si intende la{" "}
					<b>capacità potenziale di apprendere la musica.</b> L'attitudine
					musicale ha diversi gradi al momento della nascita. È stato stimato
					che un 68% dei bambini nasce con potenziali capacità medie, un 16% al
					di sopra della media e un 16% al di sotto della media. 68% di bambini
					con un'attitudine musicale di capacità medie! Ti sembra poco?
				</p>
				<p className="paragraph">
					Ma allora perché poi non sono così tanti a "svilupparsi musicalmente"?
					Perché così tanti "io non sono portato"?
					<br />
					La nostra attitudine musicale subisce tantissimo l'influenza
					dell'ambiente in cui siamo inseriti.{" "}
					<strong>Pensiamo a come impariamo a parlare.</strong> Impariamo a
					parlare perché sentiamo i nostri genitori, i nostri familiari che
					emettono parole, pronunciano delle frasi. E così anche noi piano
					piano, imitandoli, impariamo prima a ripetere le parole e poi a
					formulare delle frasi. Più ricco e vario il lessico e le frasi che
					ascoltiamo, più noi cercheremo di ripetere e formulare frasi ricche.
					<br />
					Succede lo stesso per la nostra potenzialità di fare musica.{" "}
					<strong>
						Più siamo inseriti in un ambiente musicalmente stimolante, più
						potremo sviluppare la nostra attitudine musicale.
					</strong>
					<br />
					Il professor Edwin Gordon, padre fondatore della{" "}
					<strong>Music Learning Theory</strong>, ha basato la sua teoria
					dell'apprendimento musicale proprio su queste considerazioni che sono
					state dimostrate scientificamente.
				</p>
				<p className="image">
					<img
						src={
							process.env.PUBLIC_URL + "/img/unfioredimaestra-bimbo-musica.jpg"
						}
						alt="Immagine Bimbo Musica"
					/>
				</p>
				<h3 className="titoletto">Sviluppare l'attitudine musicale</h3>
				<p className="paragraph">
					Nascere con un'attitudine alta, tuttavia, non è sufficiente ad essere
					automaticamente "talentuosi". Infatti soprattutto nel primo anno di
					vita, l'attitudine musicale inizia a decrescere e se l'ambiente in cui
					siamo inseriti non è sufficientemente stimolante, continuerà a
					decrescere.
				</p>
				<p className="paragraph">
					L'ambiente in cui viviamo influenza la nostra attitudine musicale
					nella fascia tra gli 0 e i 9 anni. Dopo i 9 anni l'ambiente musicale,
					anche se elevato, non incide più sulla nostra attitudine.
				</p>
				<p className="paragraph">
					Ma quindi dopo i 9 anni che succede? È troppo tardi per fare musica?
					No! <strong>L'attitudine rimane sempre.</strong> E anche se non
					possiamo riportarla al livello iniziale,{" "}
					<strong>
						l'attitudine musicale ci accompagna per tutta la vita e per tutta la
						vita abbiamo la possibilità di andare a riscoprirla.
					</strong>{" "}
					L'età della nostra attitudine musicale non corrisponde all'età
					anagrafica. C'è chi ha sviluppato un'attitudine alta fin da piccolo,
					ma lo scopre solo da adulto, quando inizia il corso di chitarra e
					impara velocemente le melodie, perché appunto la sua attitudine è
					alta. Ma anche se la sua attitudine è alta, non significa che non
					debba lavorarci su. Quando invece l'attitudine è bassa o media, si può
					comunque mettere in atto un processo per andare a riscoprirla e
					migliorarla.
				</p>
				<h3 className="titoletto">Tutti possiamo fare musica</h3>
				<p className="paragraph">
					Quindi hai visto? Tutti abbiamo dentro di noi la possibilità di fare
					musica! <strong>Sta solo a noi scegliere</strong> come giocarcela!{" "}
					<strong>
						Smettiamola di pensare che ci sia solo il talento o il nulla.
					</strong>{" "}
					I grandi musicisti non vivono solo di talento, ma anche di tanto
					studio e impegno. Per avere certi risultati, il talento da solo non
					basta.
					<br />
					L'importante, quando si sceglie un percorso musicale, è definire i
					propri obiettivi e rapportarli con il tuo vissuto reale: perché voglio
					fare musica? Perché è una cosa che mi fa stare bene? Per avere una
					carriera? Per provare ad affrontare una cosa nuova? Per dimostrare che
					ce la posso fare?{" "}
					<strong>
						Qualsiasi motivo valido per te è un buon motivo per iniziare!
					</strong>
					<br />
					Io sono convinta che scegliere di intraprendere uno studio musicale
					non significhi solo voler diventare "bravi" e "di talento". La musica
					può insegnare e può trasmettere molto di più.
				</p>
			</React.Fragment>
		),
	},
];

export default Articles;
