import React from "react";
import Footer from "./Footer";

const ChiSono = () => {
	return (
		<section id="chi-sono">
			<div className="page-header">
				<h1 className="page-title">Chi Sono</h1>
			</div>
			<div className="page-content">
				<p className="paragraph">
					Mi chiamo FIORENZA SUAGHER e sono nata nella provincia di Bergamo il
					27 giugno 1992.
				</p>
				<p className="paragraph">
					Mio papà M° Maurizio Suagher è musicista e insegnante e così, da
					quando sono piccola, ho la possibilità di crescere in un ambiente
					musicale <strong>attivo, libero e creativo.</strong>
				</p>
				<p className="paragraph">
					Nel 1998 mi avvicino al mondo del <strong>canto</strong> e del{" "}
					<strong>coro musicale</strong> quando mio papà inizia a portarmi con
					sé alle prove del coro polifonico da lui diretto. Sono totalmente
					affascinata dell'ambiente "coro" che per me resta una delle più belle
					forme musicali.
				</p>
				<p className="paragraph">
					Durante gli anni la mia curiosità mi porta a voler provare più
					strumenti musicali. Con mio papà studio le basi del pianoforte. Studio
					chitarra classica dal 2001 al 2003 e nel 2002 inizio lo studio del
					flauto traverso. Dal 2013 studio canto moderno. Successivamente negli
					anni 2015-2017 prendo lezioni di viola.
				</p>
				<p className="paragraph">
					Dal 2006 al 2011 frequento il liceo classico "P. Sarpi" di Bergamo ad
					indirizzo musicale, in cui ho la possibilità di studiare teoria e
					storia della musica, canto corale e seguire il corso individuale di
					flauto traverso con il M° Marcello Giolo.
				</p>
				<p className="paragraph">
					Dal 2014, parallelamente all'attività lavorativa, studio presso la
					scuola di musica Centro Didattico Produzione Musica di Bergamo dove
					seguo i corsi di <strong>tecnica vocale</strong> e{" "}
					<strong>canto pop e rock</strong> con M° Gabriella Mazza, canto corale
					con il M° Giovanni Guerini e teoria musicale con il M° Ermanno Novali.
					<br />
					Presso il CDpM sostengo dal primo anno di frequenza gli esami di
					certificazione Trinity College London per canto e flauto e London
					College of Music per teoria musicale.
				</p>
				<p className="paragraph">
					Nel 2017 divento insegnante di musica come esperto esterno nella
					scuola primaria e{" "}
					<strong>nel 2018 inizio a dare lezioni di canto individuale.</strong>
					<br />
					Attualmente continuo la collaborazione con diverse scuole di musica di
					Bergamo e provincia come insegnante di canto moderno e insegnante di
					flauto traverso per bambini e principianti. Presso le scuole ordinarie
					mi propongo come esperto esterno per i progetti di musica con
					Orizzonti Associazione Culturale.
				</p>
				<p className="paragraph">
					Nel mio percorso di insegnante mi accompagna la voglia di continuare a
					formarmi. Credo profondamente che "restare allieva" sia il segreto per
					migliorarsi come insegnante. Aggiornarmi, conoscere diversi metodi e
					confrontarmi con altri insegnanti mi dà la possibilità di essere
					pronta a trovare <strong>nuove soluzioni e nuovi entusiasmi.</strong>
				</p>
				<div className="paragraph">
					<b>ATTIVITÀ DIDATTICA</b>
					<ul>
						<li>
							2020: da novembre inizio a far parte come #littleflutelearner di
							<a
								href="https://www.fluteplay.ca/"
								rel="noopener noreferrer"
								target="_blank"
							>
								{" "}
								fluteplay
							</a>
							, una community di insegnanti di flauto internazionale che insieme
							collaborano per la ricerca e lo sviluppo di una didattica del
							flauto traverso per bambini.
						</li>
						<li>
							2019: frequento seminari dei corsi <strong>Orff-Schulwerk</strong>{" "}
							per ampliare la mia conoscenza nella didattica musicale.
						</li>
						<li>
							2019: insegno
							<strong> canto moderno e flauto traverso</strong> collaborando con
							diverse scuole di musica della provincia di Bergamo.
						</li>
						<li>
							2018: divento
							<strong> insegnante di canto moderno </strong>presso la Scuola di
							musica della Banda Musicale di Nembro.
						</li>
						<li>
							2017: inizio il mio percorso di insegnante di musica come esperto
							esterno presso la scuola primaria.
						</li>
					</ul>
				</div>
				<div className="paragraph">
					<b>FORMAZIONE</b>
					<ul>
						<li>
							2018: conseguo la certificazione in{" "}
							<strong>Popular Music Theory</strong> Grade 5 di London College of
							Music con votazione 99/100.
						</li>
						<li>
							2018: conseguo la certificazione in{" "}
							<strong>Rock e Pop Vocals</strong> Grade 8 di Trinity College
							London.
						</li>
						<li>
							2017: ricevo l'Exibition Award in Flute di Trinity College London
							relativo all'esame di flauto sostenuto nel 2016.
						</li>
						<li>
							2016: conseguo la certificazione in{" "}
							<strong>Music Performance</strong> Grade 5 Flute di Trinity
							College London con votazione 99/100.
						</li>
						<li>
							Dal 2015 al 2017: sostengo gli esami di certificazione in Rock &
							Pop Vocals di Trinity College London.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</section>
	);
};

export default ChiSono;
